@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  scroll-snap-type: proximity;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
  @apply flex justify-center items-center gap-[0.5em] px-[1.5em] py-[1em] sm:py-[0.5em] w-[100%] border-[1px] mt-[1em] border-[#AAA] rounded-[8px] cursor-pointer shadow-md;
}
.flexbm {
  @apply flex justify-between items-center;
}
.flexbs {
  @apply flex justify-between items-start;
}
.flexbe {
  @apply flex justify-between items-end;
}
.flexam {
  @apply flex justify-around items-center;
}
.flexmm {
  @apply flex justify-center items-center;
}
.flexem {
  @apply flex justify-end items-center;
}
.flexme {
  @apply flex justify-center items-end;
}
.flexsm {
  @apply flex justify-start items-center;
}
.flexms {
  @apply flex justify-center items-start;
}
.flexss {
  @apply flex justify-start items-start;
}
.flexse {
  @apply flex justify-start items-end;
}
.flexes {
  @apply flex justify-end items-start;
}

.cflexbm {
  @apply flex flex-col justify-between items-center;
}
.cflexbs {
  @apply flex flex-col justify-between items-start;
}
.cflexbe {
  @apply flex justify-between items-end;
}
.cflexam {
  @apply flex flex-col justify-around items-center;
}
.cflexmm {
  @apply flex flex-col justify-center items-center;
}
.cflexem {
  @apply flex flex-col justify-end items-center;
}
.cflexme {
  @apply flex flex-col justify-center items-end;
}
.cflexsm {
  @apply flex flex-col justify-start items-center;
}
.cflexms {
  @apply flex flex-col justify-center items-start;
}
.cflexss {
  @apply flex flex-col justify-start items-start;
}
.cflexse {
  @apply flex flex-col justify-start items-end;
}
.cflexes {
  @apply flex flex-col justify-end items-start;
}
