.awssld__loader {
    display: none;
}

.awssld__timer {
    background-color: transparent !important;
}

.slick-track {
    /* display: flex;
  gap: 100px;
  align-items: center;
  padding: 20px;
  width: auto; */
}
.slick-list {
    width: 100%;
}

.clx {
    display: flex;
}

.slider-container {
    /* display: flex; */
    position: relative;
}
