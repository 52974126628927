.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  .lds-ring-2 {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-ring-2 div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    margin: 12px;
    border: 2px solid #00AC76;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00AC76 transparent transparent transparent;
  }
  .lds-ring-2 div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring-2 div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring-2 div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }